
//  Global Component Variables
$vs-component-line-height: 1.4 !default;
$vs-component-placeholder-color: inherit !default;

//  Active State
$vs-state-active-bg: #5897fb !default;
$vs-state-active-color: #fff !default;

//  Disabled State
$vs-state-disabled-bg: rgb(248, 248, 248) !default;
$vs-state-disabled-color: map_get($vs-colors, 'light') !default;
$vs-state-disabled-controls-color: map_get($vs-colors, 'light') !default;
$vs-state-disabled-cursor: not-allowed !default;

//  Borders
$vs-border-width: 1px !default;
$vs-border-style: solid !default;
$vs-border-radius: 4px !default;
$vs-border-color: map_get($vs-colors, 'lightest') !default;

//  Component Controls: Clear, Open Indicator
$vs-controls-color: map_get($vs-colors, 'darkest') !default;
$vs-controls-size: 1 !default;
$vs-controls-deselect-text-shadow: 0 1px 0 #fff;

//  Selected
$vs-selected-bg: #f0f0f0 !default;
$vs-selected-border-color: $vs-border-color !default;
$vs-selected-border-style: $vs-border-style !default;
$vs-selected-border-width: $vs-border-width !default;
$vs-selected-color: #f0f0f0 !default;

//  Dropdown
$vs-dropdown-z-index: 1000 !default;
$vs-dropdown-min-width: 160px !default;
$vs-dropdown-max-height: 350px !default;
$vs-dropdown-box-shadow: 0px 3px 6px 0px map_get($vs-colors, 'lightest') !default;
$vs-dropdown-bg:  map_get($vs-colors, 'light') !default;

@import "../../node_modules/vue-select/src/scss/global/_component.scss";
@import "../../node_modules/vue-select/src/scss/global/animations";
@import "../../node_modules/vue-select/src/scss/global/states";

@import "../../node_modules/vue-select/src/scss/modules/dropdown-toggle";
@import "../../node_modules/vue-select/src/scss/modules/open-indicator";
@import "../../node_modules/vue-select/src/scss/modules/clear";
@import "../../node_modules/vue-select/src/scss/modules/dropdown-menu";

/* List Items */
.vs__dropdown-option {
    line-height: 1.42857143; /* Normalize line height */
    display: block;
    padding: 3px 20px;
    clear: both;
    color: map_get($vs-colors, 'darkest');
    white-space: nowrap;
  
    &:hover {
      cursor: pointer;
    }
  }
  
  .vs__dropdown-option--highlight {
    background: $vs-state-active-bg;
    color: $vs-state-active-color;
  }
  
  .vs__dropdown-option--disabled {
    background: inherit;
    color: $vs-state-disabled-color;
  
    &:hover {
      cursor: inherit;
    }
  }
  

@import "../../node_modules/vue-select/src/scss/modules/selected";
@import "../../node_modules/vue-select/src/scss/modules/search-input";
@import "../../node_modules/vue-select/src/scss/modules/spinner";

.v-select {
    background-color: map_get($vs-colors, 'light');
}

.vs__search {
  color: #b5b5b5;
}