@charset "utf-8";

@import './tbnt-richard-variables.scss';

/* BULMA CONFIGURATION */
$primary: $primary-color;
$family-primary: 'Segoe UI', 'Helvetica Neue', 'Helvetica', 'Lucida Grande', 'Arial', 'Ubuntu', 'Cantarell', 'Fira Sans', 'sans-serif';
$body-size: 15px;

$strong-color: $text-color;

$body-background-color: $primary-background-color;
$body-color: $text-color;

$title-color: $text-color;
$link: $text-color;
$link-hover: $item-hover-color;

$input-color: $text-color;
$input-background-color: $secondary-background-color;
$input-border-color: $primary-accent-color;

$navbar-background-color: $secondary-background-color;
$navbar-item-hover-background-color: $primary-accent-color;
$navbar-item-color: $text-color;
$navbar-dropdown-background-color: $primary-background-color;
$navbar-dropdown-item-hover-color: $item-hover-color;
$navbar-divider-background-color: $item-hover-color;

$button-background-color: #345c4d;

$menu-item-hover-color: $item-hover-color;
$menu-item-hover-background-color: $secondary-background-color;
$menu-item-color: $text-color;
$menu-item-active-color: $text-color;
$menu-item-active-background-color: $secondary-background-color;

$table-color: $text-color;
$table-background-color: $secondary-background-color;

$breadcrumb-item-color: $text-color;
$breadcrumb-item-active-color: $text-color;

$footer-background-color: $primary-background-color;

$title-color: $text-color;

$modal-card-head-background-color: $secondary-background-color;
$modal-card-body-background-color: $secondary-background-color;
$modal-card-foot-background-color: $secondary-background-color;
$modal-card-head-border-bottom: 0px;
$modal-card-foot-border-top: 0px;
$modal-card-title-color: $text-color;

$tag-background-color: $secondary-accent-color;
$tag-color: $text-color;

$column-gap: 3px;

$light: $primary-panel-color;
$light-invert: #000;

.tbnt-panel {
    background-color: $primary-panel-color;
    border-radius: 2px;
}

.is-dim {
    color: $secondary-accent-color;
}

.color-this-primary {
    color: $primary;
}

@import '../../node_modules/bulma/sass/utilities/_all.sass';
@import '../../node_modules/bulma/sass/base/_all.sass';
@import '../../node_modules/bulma/sass/elements/_all.sass';
@import '../../node_modules/bulma/sass/form/_all.sass';
@import '../../node_modules/bulma/sass/components/_all.sass';
@import '../../node_modules/bulma/sass/grid/_all.sass';
@import '../../node_modules/bulma/sass/helpers/_all.sass';
@import '../../node_modules/bulma/sass/layout/_all.sass';

.post-content {
    a {
        text-decoration: underline;
        color: $primary-color;
    }

    a:hover {
        text-decoration: underline;
        color: $text-color;
    }

    ul {
        list-style: square;
    }
}

$vs-colors: (
        darkest: rgba(20, 20, 20, 1.0),
        dark: rgba(50, 50, 60, 1.0),
        light: #EEE,
        lightest: rgba(255, 255, 255, .85),
) !default;
@import 'vue-select.scss'